body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #E8E8E8;
  border: solid 2px white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  margin-left: 5px;
  font-weight: 600;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 35px;
  left: -130px;
}

a:focus-visible,
a:focus,
button:focus-visible,
button:focus,
input:focus-visible,
input:focus,
textarea:focus-visible,
textarea:focus,
select:focus-visible,
select:focus,
fieldset:focus-within,
fieldset:focus,
input[type=radio]:focus-visible,
input[type=radio]:focus,
input[type=checkbox]:focus-visible,
input[type=checkbox]:focus {
  outline: 2px solid black !important;
}

button:focus-visible,
button:focus {
  border: 2px solid white !important;
}
