body {
  margin:0;
  font-family: Arial,Roboto,"Helvetica Neue",Helvetica,sans-serif;
  font-size: 14px;
}

a {
  text-decoration: underline;
  text-decoration-color: #8C1D40;
  color: #8C1D40;
}

h2 {
  font-size: 38px;
  color: #484848;
}

.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}